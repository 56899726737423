import { NavLink } from "react-router-dom";
import '../styles/app.scss'

const Nav = () => (
  <div className="nav-container">
    <header className="header-container">
        <div className="header-text">
          <h1 className="gold-text" style={{textAlign: 'left'}}>Welcome to the</h1>
          <h1 className="gold-text" style={{textAlign: 'center'}}>Disgaea Portal</h1>
          <h1 className="gold-text" style={{textAlign: 'right'}}>dood!</h1>
        </div>
      <div className="chibis">
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d1_chibi.png`} alt="Chibi" />
        </div>
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d2_chibi.png`} alt="Chibi" />
        </div>
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d3_chibi.png`} alt="Chibi" />
        </div>
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d4_chibi.png`} alt="Chibi" />
        </div>
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d5_chibi.png`} alt="Chibi" />
        </div>
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d6_chibi.png`} alt="Chibi" />
        </div>
          <div className="chibi-wrap">
        <img src={`${process.env.PUBLIC_URL}/img/d7_chibi1.png`} alt="Chibi" />
        </div>
        <div className="chibi-wrap">
          <img src={`${process.env.PUBLIC_URL}/img/d7_chibi2.png`} alt="Chibi" />
        </div>
        <div className="block"></div>
      </div>
    </header>
    <div className="nav">
      <NavLink 
        to=''
        className={({ isActive }) => isActive ? "active-navlink navlink" : "navlink" }
      >
        <button className="btn gold-text">Home</button>
      </NavLink>
      <NavLink 
        to='/about'
        className={({ isActive }) => isActive ? "active-navlink navlink" : "navlink" }
      >
        <button className="btn gold-text">About</button>
      </NavLink>
      <NavLink 
        to='/timeline'
        className={({ isActive }) => isActive ? "active-navlink navlink" : "navlink" }
      >
        <button className="btn gold-text">Timeline</button>
      </NavLink>
    </div>
  </div>
)

export default Nav;