const About = () => (
  <div className="about-container">
    <div className="pair-list">
    <h1 className="title yellow">
      What is the Disgaea Series?
    </h1>
    <div className="pair">
      <div className="pair-wrapper">
        <img src="./img/1.png" alt="" />
      </div>
      <div className="pair-text">
        <h1 className="yellow">It's a strategy RPG series that takes being ridiculous very seriously.</h1>
      </div>
    </div>
    <div className="pair reverse">
      <div className="pair-wrapper">
        <img src="./img/2.png" alt="" />
      </div>
      <div className="pair-text">
        <h1>The series is set in various <span>Netherworlds</span>. These are worlds where right and wrong are reversed, filled with a myriad of reckless demons, angels, and Overlords who do whatever they want. You can never predict what will happen next. </h1>
        <h1 className="yellow">
          There are plenty of insane, enjoyable characters and storylines.
        </h1>
      </div>
    </div>
    <div className="pair">
      <div className="pair-wrapper">
        <img src="./img/3.png" alt="" />
      </div>
      <div className="pair-text">
        <h1>You can level your characters up to <span>9999</span>. If attack power is important to you, you can achieve the ultimate thrill of dealing a hundred-million damage! DO YOU EVEN LIFT, DOOD!?</h1>
        <h1>You can even create a character that can use any skill!</h1>
        <h1 className="yellow">Raise your characters beyond the limit!</h1>
      </div>
    </div>
    <div className="pair reverse">
      <div className="pair-wrapper">
        <img src="./img/4.png" alt="" />
      </div>
      <div className="pair-text">
        <h1>Anything's possible at the <span>Dark Assembly</span>. As your violent reputation at the <span>Dark Assembly</span> rises, your merits will also increase, and so on. </h1>
        <h1 className="yellow">Push forward to forge your own path. There are lots of options to create your own playing style in this over-the-top game.</h1>
      </div>
    </div>
    <div className="pair">
      <div className="pair-wrapper">
        <img src="./img/5.png" alt="" />
      </div>
      <div className="pair-text">
        <h1>
          All the characters designed by <span>Mr. Takehito Harada</span> are cute and cool!<br/>
          You'll get drunk off the music and you may even break into song!
          </h1>
          <h1 className="yellow">The visuals and audio add even more excitement to the game!</h1>
      </div>
    </div>
    <div className="pair reverse">
      <div className="pair-wrapper">
        <img src="./img/6.png" alt="" />
      </div>
      <div className="pair-text">
        <h1>Basically...</h1>
        <h1 className="yellow">You'll have fun playing it, and before you know it, you'll be addicted!</h1>
        <h1>      
          Maximum sensory stimulation and addictiveness are what the Disgaea series is all about!
        </h1>
      </div>
    </div>
    <div className="single">
      <div className="pair-wrapper">
        <img src="./img/7.png" alt="" />
      </div>
    </div>
    </div>
  </div>
)

export default About;