import './styles/top.scss'

const Top = () => (
  <div className="page-container">
    <section className="banner-list">
      <a href="https://www.nisamerica.com/disgaea/d7/" target="_blank" rel="noopener noreferrer">
        <div className="game-banner bg7">
          <div className="fp">
            <div className="fp-wrapper large">
              <img src={`${process.env.PUBLIC_URL}/img/footer/ps45.png`} alt="Logos" />
            </div>
            <div className="fp-wrapper">
              <img src={`${process.env.PUBLIC_URL}/img/footer/nsw-large.webp`} alt="Logos" />
            </div>
            <div className="fp-wrapper">
              <img src={`${process.env.PUBLIC_URL}/img/footer/steam_white.svg`} alt="Logos" />
            </div>
          </div>
          <div className="wrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/d7_logo.png`} alt="Disgaea 7 Logo" className="logo" />
          </div>
        </div>
      </a>
      <a href="https://nisamerica.com/disgaea/d6-complete/" target="_blank" rel="noopener noreferrer">
        <div className="game-banner banner-reverse bg6" >
          <div className="fp-reverse">
            <div className="fp-wrapper large">
              <img src={`${process.env.PUBLIC_URL}/img/footer/ps45.png`} alt="Logos" />
            </div>
            <div className="fp-wrapper">
              <img src={`${process.env.PUBLIC_URL}/img/footer/nsw-large.webp`} alt="Logos" />
            </div>
            <div className="fp-wrapper">
              <img src={`${process.env.PUBLIC_URL}/img/footer/steam_white.svg`} alt="Logos" />
            </div>
          </div>
          <div className="wrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/d6c_logo.png`} alt="Disgaea 6 Complete Logo" className="logo" />
          </div>
        </div>
      </a>
      <a href="https://www.nisamerica.com/disgaea/d5-complete/" target="_blank" rel="noopener noreferrer">
        <div className="game-banner bg5">
          <div className="fp">
            <div className="fp-wrapper solo">
              <img src={`${process.env.PUBLIC_URL}/img/footer/nsw-large.webp`} alt="Logos" />
            </div>
          </div>
          <div className="wrapper1">
            <img src={`${process.env.PUBLIC_URL}/img/d5c_logo.png`} alt="Disgaea 5 Complete Logo" className="logo" />
          </div>
        </div>
      </a>
      <a href="https://www.nisamerica.com/disgaea/d4-complete/" target="_blank" rel="noopener noreferrer">
      <div className="game-banner banner-reverse bg4">
      <div className="fp-reverse">
      <div className="fp-wrapper large">
            <img src={`${process.env.PUBLIC_URL}/img/footer/ps4.png`} alt="Logos" style={{width: '150px'}}/>
          </div>
          <div className="fp-wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/footer/nsw-large.webp`} alt="Logos" />
          </div>
          <div className="fp-wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/footer/steam_white.svg`} alt="Logos" />
          </div>
          <div className="fp-wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/footer/game_pass.png`} alt="Logos" />
          </div>
        </div>
        <div className="wrapper1 large-logo">
          <img src={`${process.env.PUBLIC_URL}/img/d4c_logo.png`} alt="Disgaea 4 Complete Logo" className="logo"/>
        </div>
      </div>
      </a>
      <a href="https://www.nisamerica.com/disgaea/mobile/" target="_blank" rel="noopener noreferrer">
        <div className="game-banner bg1">
          <div className="fp">
          <div className="fp-wrapper">
              <img src={`${process.env.PUBLIC_URL}/img/footer/download_google.png`} alt="Logos" />
            </div>
            <div className="fp-wrapper">
              <img src={`${process.env.PUBLIC_URL}/img/footer/download_appstore.png`} alt="Logos" />
            </div>
          </div>
          <div className="wrapper1 d1c">
            <img src={`${process.env.PUBLIC_URL}/img/d1c_logo.png`} alt="Disgaea 1 Complete Logo" className="logo" />
          </div>
        </div>
      </a>
    </section>

    <div className="other-titles">
      <img src={`${process.env.PUBLIC_URL}/img/series_cap.png`} alt="bannner" className='cap'/>
      <div className="title-list">
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title11.png`} alt="Title image" />
          <div className="link-list">
            <a href="https://www.nisamerica.com/disgaea/d6/" target="_blank" rel="noopener noreferrer">
            <button>Nintendow Switch™</button>
            </a>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title09.png`} alt="Title image" />
          <div className="link-list">
            <a href="https://store.steampowered.com/app/405900/Disgaea_PC/" target="_blank" rel="noopener noreferrer">
              <button>Steam</button>
            </a>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title06.png`} alt="Title image" />
          <div className="link-list">
            <button>PS4™</button>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title04.png`} alt="Title image" />
          <div className="link-list">
            <button>PS3™</button>
            <a href="https://www.nisamerica.com/disgaea/d4vita/index.html" target="_blank" rel="noopener noreferrer">
              <button>PS Vita</button>
            </a>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title03.png`} alt="Title image" />
          <div className="link-list">
            <button>PS3™</button>
            <button>PS Vita</button>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title02.png`} alt="Title image" 
          />
          <div className="link-list">
            <button>PS2™</button>
            <button>PSP™</button>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title01.png`} alt="Title image" />
          <div className="link-list">
            <button>PS2™</button>
            <button>PSP™</button>
            <button>Nintendo DS™</button>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title05.png`} alt="Title image" />
          <div className="link-list">
            <button>PSP™</button>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title00.png`} alt="Title image" />
          <div className="link-list">
            <button>PSP™</button>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title08.png`} alt="Title image" />
          <div className="link-list">
            <a href="https://prinnies.com/" target="_blank" rel="noopener noreferrer"><button>Nintendo Switch™</button></a>
          </div>
        </div>
        <div className="title">
          <img src={`${process.env.PUBLIC_URL}/img/tile/title07.png`} alt="Title image" />
          <div className="link-list">
            <a href="https://prinnies.com/" target="_blank" rel="noopener noreferrer"><button>Nintendo Switch™</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
)


export default Top;