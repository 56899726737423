import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/utility/ScrollToTop";
import GA from "./components/utility/GA";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import './styles/app.scss'
import Top from "./Top";
import About from "./About";
import Timeline from "./Timeline";

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <GA GA4="" UA=""/>
        <Nav/>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Top/>}/>
            <Route path="about" element={<About/>}/>
            <Route path="timeline" element={<Timeline/>}/>
          </Routes>
        </ScrollToTop>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
