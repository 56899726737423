import { useState } from 'react'
import '../styles/footer.scss'

const Footer = () => {
    const [email, setEmail] = useState('')
    return (
        <footer className='footer-container'>
          <div className='social-wrapper'>
              <div className='social-list'>
                  <div className='social-button'>
                      <a
                          href='https://twitter.com/nisamerica'
                          target='_blank'
                          rel='noreferrer'
                          data-label='Twitter'
                      >
                          <img src={`${process.env.PUBLIC_URL}/img/footer/twitter.svg`} alt="" />
                      </a>
                  </div>
                  <div className='social-button'>
                      <a
                          href='https://www.facebook.com/NISAmericaInc/'
                          target='_blank'
                          rel='noreferrer'
                          className=''
                          data-label='Facebook'
                      >
                          <img src={`${process.env.PUBLIC_URL}/img/footer/facebook.svg`} alt="" />
                      </a>
                  </div>
                  <div className='social-button'>
                      <a
                          href='https://www.instagram.com/nisamerica/'
                          target='_blank'
                          rel='noreferrer'
                          className=''
                          data-label='Instagram'
                      >
                          <img src={`${process.env.PUBLIC_URL}/img/footer/instagram.svg`} alt="" />
                      </a>
                  </div>
                  <div className='social-button'>
                      <a
                          href='https://www.youtube.com/user/NISAmerica'
                          target='_blank'
                          rel='noreferrer'
                          className=''
                          data-label='YouTube'
                      >
                          <img src={`${process.env.PUBLIC_URL}/img/footer/youtube.svg`} alt="" />
                      </a>
                  </div>
                  <div className='social-button'>
                      <a
                          href='https://www.twitch.tv/nisamerica'
                          target='_blank'
                          rel='noreferrer'
                          className=''
                          data-label='Twitch'
                      >
                          <img src={`${process.env.PUBLIC_URL}/img/footer/twitch.svg`} alt="" />
                      </a>
                  </div>
                  <div className='social-button'>
                      <a
                          href='https://discord.gg/nisa'
                          target='_blank'
                          rel='noreferrer'
                          className=''
                          data-label='Discord'
                      >
                          <img src={`${process.env.PUBLIC_URL}/img/footer/discord.svg`} alt="" />
                      </a>
                  </div>
              </div>
              <div className='mailchimp'>
                  <form
                      action='https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&amp;id=349f7d20f9'
                      method='post'
                      id='mc-embedded-subscribe-form'
                      name='mc-embedded-subscribe-form'
                      className='validate'
                      target='_blank'
                      noValidate=''
                  >
                      <div id='mc_embed_signup_scroll' className='mailchimp text-center'>
                          <div className='sub-label'>
                              <label htmlFor='mce-EMAIL' className='subscribe text-sub'>
                                  Subscribe to our mailing list to get the latest information!
                              </label>
                          </div>

                          <div className='email-pair'>
                              <input
                                  className='email-input'
                                  type='email'
                                  placeholder='Your Email'
                                  value={email}
                                  name='b_e8fc423eff95099d1ffcc6009_349f7d20f9'
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                              <button className='bg-rt3egray/80 backdrop-blur text-sm tracking-wider text-white px-2 md:px-8 py-2 uppercase'>
                                  Subscribe
                              </button>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
          <div className='first-party'>
              <div className='rating'>
                  <img className='mx-auto' src={`${process.env.PUBLIC_URL}/img/footer/esrb.jpg`} alt='esrb rp-e10' height='200px'/>
              </div>
              <div className=' w-full basis-1/2 md:basis-1/4'>
                  <img className='mx-auto w-4/5' src={`${process.env.PUBLIC_URL}/img/footer/nisj-white.png`} alt='nisj' id='large'/>
              </div>
              <div className=' w-full basis-1/2 md:basis-1/4'>
                  <img className='mx-auto w-4/5' src={`${process.env.PUBLIC_URL}/img/footer/NIS_logo.webp`} alt='nis' id='large'/>
              </div>
          </div>

          <hr/>

          <div className='legal'>
              <div>
                  <p>
                     ©2023 Nippon Ichi Software, Inc. ©2023 NIS America, Inc. All rights reserved. Disgaea is a trademark or registered trademark of Nippon Ichi Software, Inc.
                  </p>
                  <p>
                      ©2023 Sony Interactive Entertainment LLC. "<span className='font-sie'>1</span>",
                      "PlayStation", "<span className='font-sie'>5</span>", "PS5”, "
                      <span className='font-sie'>4</span>", "PS4", "<span className='font-sie'>0</span>" and "Play
                      Has No Limits" are registered trademarks or trademarks of Sony Interactive Entertainment
                      Inc.
                  </p>
                  <p>
                    Nintendo Switch is a trademark of Nintendo.
                  </p>
                  <p>
                  © 2023 Valve Corporation. Steam and the Steam logo are trademarks and/or registered trademarks of Valve corporation in the US and/or other countries. All rights reserved.
                  </p>
                  <p>
                      The rating icon is a trademark of the Entertainment Software Association. All other
                      trademarks are properties of their respective owners.
                  </p>
              </div>
          </div>
        </footer>
    )
}

export default Footer