import './styles/timeline.scss'

const Timeline = () => (
  <div className="timeline-container">
    <h1 className="timeline-title gold-text">
      Series Timeline </h1>
    <h1 className='timeline-para gold-text'>
      There's going to be a test?! If you check this out you will know the Disgaea series history, dood.
</h1>
    <div className="timeline-list">
      <div className="year">
        <div className="left gold-text gold-text">
          2003
        </div>
        <div className="main-title">
          <img src="./img/d1_logo.png" alt="logo" />
          <h1>Disgaea: Hour of Darkness<br/> <span>PlayStation®2</span> </h1>
        </div>
      </div>
      <div className="year">
        <div className="left gold-text">
          2006
        </div>
        <div className="main-title">
          <img src="./img/d2_logo.png" alt="logo" />
          <h1>Disgaea 2: Cursed Memories<br/> <span>PlayStation®2</span> </h1>
        </div>
      </div>
      
    <div className="year">
      <div className="left gold-text">
        2007
      </div>
      <div className="title">
        <h1>Disgaea: Afternoon of Darkness<br/> <span>PlayStation®Portable</span> </h1>
      </div>
      <div className="year">
      <div className="left gold-text">
        2008
      </div>
      <div className="main-title">
        <img src="./img/d3_logo.png" alt="logo" />
        <h1>Disgaea 3: Absence of Justice<br/> <span>PlayStation®3</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea DS<br/> <span>Nintendo DS™</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2009
      </div>
      <div className="title">
        <h1>Disgaea 2: Dark Hero Days<br/> <span>PlayStation®Portable</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea 3 "Raspberyl Mode"<br/> <span>PlayStation®3 - DLC</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2010
      </div>
      <div className="title">
        <h1>Disgaea Infinite<br/> <span>PlayStation®Portable</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2011
      </div>
      <div className="main-title">
      <img src="./img/d4_logo.png" alt="logo" />
        <h1>Disgaea 4: A Promise Unforgotten<br/> <span>PlayStation®3</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea 4 "The Fuka and Desco Show"<br/> <span>PlayStation®3 - DLC</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2012
      </div>
      <div className="title">
        <h1>Disagea 3: Absence of Detention<br/> <span>PlayStation®Vita</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2013
      </div>
      <div className="main-title">
        <img src="./img/dd2_logo.png" alt="logo" />
        <h1>Disgaea D2: A Brighter Darkness<br/> <span>PlayStation®3</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2014
      </div>
      <div className="title">
        <h1>Disgaea 4: A Promise Revisited<br/> <span>PlayStation®Vita</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2015
      </div>
      <div className="main-title">
        <img src="./img/d5_logo.png" alt="logo" />
        <h1>Disgaea 5: Alliance of Vengence<br/> <span>
        PlayStation®4</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2016
      </div>
      <div className="title">
        <h1>Disgaea PC<br/> <span>PC</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea 3: Absence of Justice<br/> <span>PS Now</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea 4: A Promise Unforgotten<br/> <span>PS Now</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea D2: A Brighter Darkness<br/> <span>PS Now</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2017
      </div>
      <div className="title">
        <h1>Disgaea 2 PC<br/> <span>PC</span></h1>
      </div>
      <div className="title">
        <h1>Disgaea 5 Complete<br/> <span>Nintendo Switch™</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2018
      </div>
      <div className="title">
        <h1>Disgaea 1 Complete<br/> <span>Nintendo Switch™, PlayStation®4</span> </h1>
      </div>
      <div className="title">
        <h1>Disgaea 5 Complete<br/> <span>pc</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2019
      </div>
      <div className="title">
        <h1>Disgaea 4 Complete + <br/> <span>Nintendo Switch™, PlayStation®4</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2020
      </div>
      <div className="title">
        <h1>Disgaea 4 Complete +<br/> <span>PC</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2021
      </div>
      <div className="main-title">
        <img src="./img/d6_logo.png" alt="logo" />
        <h1>Disgaea 6: Defiance of Destiny<br/> <span>Nintendo Switch™</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2022
      </div>
      <div className="title">
        <h1>Disgaea 6 Complete<br/> <span>PlayStation®4, PlayStation®5, PC</span> </h1>
      </div>
    </div>
    <div className="year">
      <div className="left gold-text">
        2023
      </div>
      <div className="main-title">
        <img src="./img/d7_logo.png" alt="logo" />
        <h1>Disgaea 7: Vows of the Virtueless<br/> <span>Nintendo Switch™, PlayStation®4,<br/> PlayStation®5, PC</span> </h1>
      </div>
    </div>
  </div>
  </div>
  </div>
)

export default Timeline;